import React from "react";
import { Link } from "react-scroll";
export default function Header(props) {
  return (
    <header className="container-fluid text-center">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid header-container">
          <a className="navbar-brand" href={props.url}>
            <img alt="" className="logo" src="./images/logo.png" />
            <p>Fast. Simple.</p>
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img alt="" src="./images/menu.png" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 text-center">
              <li className="nav-item active">
                <Link
                  to="home"
                  spy={true}
                  smooth={true}
                  hashSpy={true}
                  // offset={50}
                  duration={500}
                  // delay={1000}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={500}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  hashSpy={true}
                  // offset={50}
                  duration={500}
                  // delay={1000}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={500}
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="how-it-works"
                  spy={true}
                  smooth={true}
                  hashSpy={true}
                  // offset={50}
                  duration={500}
                  // delay={1000}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={500}
                >
                  How it works
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="features"
                  spy={true}
                  smooth={true}
                  hashSpy={true}
                  // offset={50}
                  duration={500}
                  // delay={1000}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={500}
                >
                  Features
                </Link>
              </li>
              {/* INFO: Removed as per client requirement on 28 nov 2024 */}
              {/* <li className="nav-item">
                <Link
                onClick={()=> window.open("http://localhost:3000/session/signin")}
                  // to="http://localhost:3000/session/signin"
                >
                  Login
                </Link>
              </li> */}
            </ul>
            <form className="d-flex">
              <button
                className="btn btn-outline-success join-beta-outline"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#joinModal"
              >
                Join Beta
              </button>
            </form>
          </div>
        </div>
      </nav>

      <div className="container mt-5 hero" id="home">
        <div className="row">
          <div className="col-md-6 person">
            <img alt="" src="./images/hero.png" />
          </div>
          <div className="col-md-6 info">
            <span>Your Method. Our Tech</span>
            <h3>
              Empower Your Soccer Scouting and Coaching with{" "}
              <span className="blue">SportoJet</span>
            </h3>
            <p className="mt-3">
              Discover Talent & Develop Players by Capturing & Presenting Your
              Observations Effortlessly.
            </p>
            <button
              className="btn btn-outline-success join-beta mt-3"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#joinModal"
            >
              <i className="fa-solid fa-arrow-left"></i> Join Beta
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
