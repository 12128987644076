import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Footer(props) {
  const currentYear = new Date().getFullYear();

  const [visible, setVisible] = useState(false);

  // Show Button After Scrolling Down More than 500px
  const toggleVisible = () => {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    // Listen for Scrolling Event
    window.addEventListener("scroll", toggleVisible, false);
    return () => {
      window.removeEventListener("scroll", toggleVisible, false);
    };
  }, []);

  // Get Back Top when Clicked
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <footer className="mt-auto mt-5">
      <div className="row">
        <div className="col-sm-12 text-center">
          <a className="navbar-brand" href={props.url}>
            <img alt="" className="logo" src="./images/logo.png" />
          </a>
          <p className="mt-3">
            &copy;{currentYear} All Rights Reserved. SportoJet Inc.
            <br />
            support@sportojet.com
          </p>
          <p>
            <a href={"/privacy-policy"} style={{textDecoration:"none", color:"#fff"}}>Privacy Policy</a>
          </p>
        </div>
      </div>

      <a href="#" className={visible ? "back-to-top" : "back-to-top d-none"} onClick={handleScroll}>
        <i className="fas fa-angle-up" aria-hidden="true"></i>
      </a>

      {/* <a href="/scouting-report">Scouting Report</a>

      <a href="/feedback-report">Feedback Report</a> */}
    </footer>
  );
}
