import { USER_SIGNUP_SUCCESS, USER_SIGNUP_ERROR, USER_SUCCESS_CLEAR, USER_ERROR_CLEAR } from "../types/userType"



const initialState = {
    error: "",
    successMessage: "",
  };
  



  
export const userReducer = (state = initialState, action) => {
    const { payload, type } = action;
    // console.log(payload);

    if(type === USER_SIGNUP_SUCCESS ){
        return {
            ...state,
            successMessage: payload.successMessage
        }
    }

    if(type === USER_SIGNUP_ERROR ){
        return {
            ...state,
            error: payload.error
        }
    }

    if(type === USER_SUCCESS_CLEAR ){
        return {
            ...state,
            successMessage: ''
        }
    }

    if(type === USER_ERROR_CLEAR ){
        return {
            ...state,
            successMessage: ''
        }
    }

  
    return state;
  }