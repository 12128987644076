import { BRAND_GET_SUCESS, BRAND_GET_FAIL } from "../types/brandTypes";

const initialState = {
  brandErrorMessage: "",
  brandSuccessMessage: "",
  brandData: {},
};

export const BrandReducer = (state = initialState, action) => {
  const { payload, type } = action;

  if (type === BRAND_GET_SUCESS) {
    return {
      ...state,
      brandData: payload.data,
    };
  }
  if (type === BRAND_GET_FAIL) {
    return {
      ...state,
      brandSuccessMessage: payload.errorMessage,
    };
  }
  return state;
};
