import {
    BRAND_GET_SUCESS,
    BRAND_GET_FAIL
} from "../types/brandTypes" 

import { SERVER_URI } from "../../Config/keys";

import axios from "axios";

export const GetBrand = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                adminid: data.adminId,
            }
        }
        let token = localStorage.getItem('authToken')
        try {
            const response = await axios.get( `${SERVER_URI}/brand/getBrand`, config );

            dispatch({
                type: BRAND_GET_SUCESS,
                payload: {
                    data: response.data.data
                },
            });
        } catch (error) {
            let data = error?.response?.data?.message;
            dispatch({
                type: BRAND_GET_FAIL,
                payload: {
                    errorMessage: data,
                },
            });
        }
    };
};