import { SERVER_URI } from "../../Config/keys";
import axios from 'axios';

import { USER_SIGNUP_SUCCESS, USER_SIGNUP_ERROR, USER_SUCCESS_CLEAR, USER_ERROR_CLEAR } from "../types/userType"


export const userSignup = (data) => {
    return async (dispatch) => {

        try {
            const response = await axios.post(`${SERVER_URI}/admin/registration`, data)

            dispatch({
                type: USER_SIGNUP_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    // token : response.data.token
                }
            })

        } catch (error) {
            let data = error?.response?.data?.message
            // console.log(data)
            dispatch({
                type: USER_SIGNUP_ERROR,
                payload: {
                    error: data
                }
            })
        }
    }
}
