import { configureStore } from "@reduxjs/toolkit";
import { createStore, compose, combineReducers, applyMiddleware } from "redux";

import { userReducer } from "./reducers/userReducers";
import { reportReducer } from "./reducers/reportsReducers";
import { BrandReducer } from "./reducers/brandsReducers";

const rootReducer = combineReducers({
  users: userReducer,
  report: reportReducer,
  brand: BrandReducer,
});

const store = configureStore({ reducer: rootReducer });
export default store;
