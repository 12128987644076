import { FINISHED_REPORT_GET_BYPID_SUCCESS, FINISHED_REPORT_GET_BYPID_ERROR } from "../../Store/types/reportsType"

const reportsState = {
    reportData: {},
    reportError: "",
    reportSuccessMessage: "",
  };



    
export const reportReducer = (state = reportsState, action) => {
    const { payload, type } = action;
    // console.log(payload);

    if(type === FINISHED_REPORT_GET_BYPID_SUCCESS ){
        return {
            ...state,
            reportData: payload.reportData
        }
    }

    if(type === FINISHED_REPORT_GET_BYPID_ERROR ){
        return {
            ...state,
            reportError: payload.reportError
        }
    }
  
    return state;
  }